@import "../../styles/mixins.scss";
@import "../../styles/variables.scss";

.backdrop {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: rgba(109 109 109 / 50%);
    z-index: 500;
}

.popup_container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 700;
    outline: 0;
    max-height: 600px;
    min-width: 300px;
    max-width: 871px;
    background: $white;
    overflow-x: hidden;
    overflow-y: auto;
    .header_background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        min-height: 48px;
        background: rgba(249, 249, 250, 0.7);
    }
    .footer_background {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        min-height: 54px;
        background: rgba(249, 249, 250, 1);
    }
    @include border-radius(10px);
    &__inr {
        &__header {
            border-radius: 8px 8px 0 0;
            display: flex;
            justify-content: space-between;
            padding: 0.3rem;
            align-items: center;

            &.v3margin {
                margin: -5px 0 20px 0;
            }

            .header_text {
                font-size: 15px !important;
                font-weight: 500;
                color: #101941;
                z-index: 1;
            }
            .close_icon {
                z-index: 9;
                display: inline-block;
                z-index: 99999999999;
                &:hover {
                    cursor: pointer;
                    svg path {
                        fill: #000;
                    }
                }
                svg {
                    vertical-align: middle;
                }
            }
        }

        .sub_heading {
            color: #fc5735;
            font-size: 10px;
            padding: 0rem 0.3rem;
            font-weight: 400;
        }

        .button_container {
            position: relative;
            width: 100%;
            @include flex-right;
            &.v3Button {
                position: absolute;
                bottom: 12px;
                right: 20px;
                button {
                    color: $primary-orange;
                    background-color: $white;
                    border: 1px solid $primary-orange;
                    label {
                        display: block;
                        align-content: center;
                        cursor: pointer;
                        height: 100%;
                    }

                    .submitButton_submit_v3 {
                        display: none;
                    }
                }
            }
            button {
                width: 140px;
                @include font-size(15);
                &:first-child {
                    margin-right: 10px;
                    background-color: $light-grey;
                    color: $black;
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .popup_container {
        max-height: 700px;
        color: #101941;
    }
}
