.container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    // user-select: none;
    .header {
        height: 72px;
        border-bottom: 2px solid #e8e8ec;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .heading {
            font-weight: 700;
            font-size: 20px;
            margin-left: 22px;
        }
    }
    .body {
        position: relative;
        display: flex;
        justify-content: center;
        height: 72vh;
        overflow: scroll;
        .img_doc {
            margin: 10px;
            width: auto;
        }
        .overlay_top {
            position: absolute;
            top: 0;
            left: 0;
            width: 98.5%;
            height: 89.5%;
            // outline: 1px solid red;
        }
        .overlay_left {
            position: absolute;
            top: 0;
            right: 16px;
            width: 35%;
            height: 97%;
            // outline: 1px solid green;
        }
        .overlay_right {
            position: absolute;
            top: 0;
            left: 0;
            width: 36.5%;
            height: 97%;
            // outline: 1px solid yellow;
        }
    }
    .download {
        width: 243px;
        height: 43px;
        background-color: #fc441e;
        font-size: 15px;
        font-weight: 700;
        border-radius: 5px;
        text-align: center;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 32px;
        bottom: 20px;
        cursor: pointer;
        text-decoration: none;
        color: #fff;
    }
}
