//Flex center items
@mixin flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

//Flex right items
@mixin flex-right {
    display: flex;
    align-items: center;
    justify-content: right;
  }
//Flex left items
@mixin flex-left {
    display: flex;
    align-items: center;
    justify-content: left;
  }
//Flex space between items
@mixin flex-space-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
//Flex space evenly items
@mixin flex-space-evenly {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
//Flex space around items
@mixin flex-space-around {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

@mixin flex {
    display: flex;
    align-items: center;
  }
  @mixin grid {
    display: grid;
  }


// For Border Radius
@mixin border-radius($r) {
  border-radius: $r;
}


// For different screen resolutions
@mixin breakpoint($point) {
  @if $point == desktop {
    @media (min-width: 1366px) {
      @content;
    }
  } @else if $point == laptop {
    @media (min-width: 1400px) and (max-width: 1599px) {
      @content;
    }
  } @else if $point == lg-desktop {
    @media (min-width: 1600px) and (max-width: 1920px) {
      @content;
    }
  } @else if $point == Ipad {
    @media (min-width: 768px) and (max-width: 1023px) {
      @content;
    }
  } @else if $point == Ipad-pro {
    @media (min-width: 1024px) and (max-width: 1279px) {
      @content;
    }
  } @else if $point == mobileonly {
    @media (max-width: 640px) {
      @content;
    }
  } @else if $point == desktop1025 {
    @media (min-width: 1025px) and (max-width: 1365px) {
      @content;
    }
  } @else if $point == desktop1280 {
    @media (min-width: 1280px) and (max-width: 1365px) {
      @content;
    }
  } @else if $point == sm-mobileonly {
    @media (min-width: 320px) and (max-width: 767px) {
      @content;
    }
  } @else if $point == landscape-mobileonly {
    @media (min-width: 480px) and (max-width: 767px) and (orientation: landscape){
      @content;
    }
  }
  @else if $point == landscape-smalldevice {
    @media (min-width: 767px) and (max-width: 1023px) and (orientation: landscape) {
      @content;
    }
  }
  @else if $point == landscape-mediumdevice {
    @media (min-width: 1024px) and (max-width: 1279px) and (orientation: landscape) {
      @content;
    }
  }
}


//For margin and padding
@mixin space($type, $direction, $first, $second) {
  @if $type == padding {
    @if $direction == all {
      padding: $first;
    } @else if $direction == top-bottom {
      padding-top: $first;
      padding-bottom: $second;
    } @else if $direction == left-right {
      padding-left: $first;
      padding-right: $second;
    } @else if $direction == top {
      padding-top: $first;
    } @else if $direction == bottom {
      padding-bottom: $first;
    } @else if $direction == left {
      padding-left: $first;
    } @else if $direction == right {
      padding-right: $first;
    } @else if
      (
        $direction ==
          top
          AND
          $direction ==
          right
          AND
          $direction ==
          bottom
          AND
          $direction ==
          left
      )
    {
      padding: $first $second $first $second;
    }
  }
  @if $type == margin {
    @if $direction == all {
      padding: $first;
    } @else if $direction == top-bottom {
      margin-top: $first;
      margin-bottom: $second;
    } @else if $direction == left-right {
      margin-left: $first;
      margin-right: $second;
    } @else if $direction == top {
      margin-top: $first;
    } @else if $direction == bottom {
      margin-bottom: $first;
    } @else if $direction == left {
      margin-left: $first;
    } @else if $direction == right {
      margin-right: $first;
    }
  }
}

/*Font size*/
@function calculateRem($size) {
    $remSize: ($size / 16);
    @return $remSize * 1rem;
  }
  
  @mixin font-size($size) {
    font-size: calculateRem($size);
  }
  
  @mixin font-sizeImp($sizeimp) {
    font-size: calculateRem($sizeimp) !important;
  }
