@import "../../styles/variables.scss";

@media(min-width:768px){
    .input_container{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width:100%;
        &.disabled {
            opacity: 0.5;
        }
        &.error_box{
            border: 1px solid $red !important;
        }
        .input{
            box-sizing: border-box;
            width:100%;
            height: 30px;
            background: rgba(232, 232, 236, 0.2);
            border: 1px solid rgba(232, 232, 236, 0.2);
            border-bottom: 1px solid #E5E7EB;
            padding-left: 5px;
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            // line-height: 13px;
            letter-spacing: 0.005em;
            // text-transform: capitalize;
            color: #70758D;
        }
    
        .input:focus {
            outline: none;
        }
    
        .search_button{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 0px;
            width: 30px;
            height: 30px;
            background: #FC441E;
            border-radius: 0px 6px 6px 0px;
            flex: none;
            order: 0;
            flex-grow: 0;
            z-index: 0;
    
            img{
                width: 14px;
                height: 14px;
                // background: #FFFFFF;
                flex: none;
                order: 0;
                flex-grow: 0;
            }
        }
    }
}


@media(max-width:767px){
    .input_container{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        &.disabled {
            opacity: 0.5;
        }
        &.error_box{
            border: 1px solid $red !important;
        }
        .input{
            box-sizing: border-box;
            width: 100%;
            height: 40px;
            background: rgba(232, 232, 236, 0.2);
            border: 1px solid rgba(232, 232, 236, 0.2);
            border-bottom: 1px solid #E5E7EB;
            padding-left: 5px;
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            line-height: 13px;
            letter-spacing: 0.005em;
            // text-transform: capitalize;
            color: #70758D;
        }
    
        .input:focus {
            outline: none;
        }
    
        .search_button{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 0px;
            width: 40px;
            height: 40px;
            background: #FC441E;
            border-radius: 0px 6px 6px 0px;
            flex: none;
            order: 0;
            flex-grow: 0;
            z-index: 0;
    
            img{
                width: 14px;
                height: 14px;
                // background: #FFFFFF;
                flex: none;
                order: 0;
                flex-grow: 0;
            }
        }
    }
}
.error_text{
    color:$red !important;
    font-weight: 400;
    font-size: 10px;
    padding: 2px 0px 0px 5px;
}