@import "../../styles/variables.scss";

.form_container {
  width: 297px;
  position: relative;
  top: -25px;
  max-height: 630px;

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
  }

  .form_header {
    color: #101941;
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 18.5px;
    position: relative;
    top: -7px;

    .sub_heading {
      color: #FC5735;
      font-size: 10px;
      font-weight: 400;
    }
  }

  .form_body {
    max-height: 470px;
    overflow: scroll;

    &::-webkit-scrollbar {
      width: 0px;
      /* width of the scrollbar */
      height: 0px;
    }

    .section_heading {
      color: #FC5735;
      font-size: 10px;
      font-weight: 500;
      line-height: 20px;
    }

    .form_section {
      margin-bottom: 18.5px;

      .section_header {
        color: #101941;
        font-size: 12px;
        font-weight: 500;
      }

      .section_body {

        &__date_container {
          input {
            width: 100%;
            outline: 0;
            border: 0;
            height: 30px;
            background: transparent;
            padding-left: 5px;
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            line-height: 13px;
            letter-spacing: 0.005em;
            color: #70758D;
          }

          input[type="date" i]::-webkit-calendar-picker-indicator {
            cursor: pointer;
            background-image: url(../../assets/images/calendar.svg)
          }

        }

      }
    }
  }
  .lat_error_text{
    color: $red !important;
    font-weight: 400;
    font-size: 10px;
    padding: 2px 0px 0px 5px;
  }
  .error_text{
    color: $red !important;
    font-weight: 400;
    font-size: 10px;
    padding: 2px 0px 0px 5px;
    display: flex;
    justify-content:space-around;
    align-items: center;
    position: relative;
      bottom: -15px;
}

  .form_footer {
    display: flex;
    justify-content: space-around;
    position: relative;
    bottom: -32px;

    .cancel {
      background-color: #F9F9FA;
      color: #101941;
    }

    .save {
      background-color: #FC441E;
      color: #fff
    }

    .form_footer_button {
      width: 114px;
      height: 30px;
      border-radius: 6px;
      font-size: 15px;
      font-weight: 500;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
}