@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.tooltip {
    position: relative;
    display: inline-block;
    top: -2px;
}

.tooltip .tooltiptext_left {
    visibility: true;
    width: 134px;
    background-color: #FFFFFF;
    color: $secondary-blue;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    box-shadow: 0 -2px 4px rgba(0, 0, 0, .08), -2px 2px 4px rgba(0, 0, 0, .08);
    top: 95%;
    left: 50%;
    margin-left: -60px;
}

.tooltip .tooltiptext_left::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    box-shadow: 0 10px 10px -17px rgba(0, 0, 0, 0.8);
    border-color: transparent transparent #FFFFFF transparent;
}

.tooltip .tooltiptext_top {
    visibility: true;
    width: 134px;
    background-color: #FFFFFF;
    color: $secondary-blue;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    box-shadow: 0 -2px 4px rgba(0, 0, 0, .08), -2px 2px 4px rgba(0, 0, 0, .08);
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip .tooltiptext_top::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #FFFFFF transparent transparent transparent;
}

// .tooltip:hover {
//     .tooltiptext_top {
//         visibility: visible;
//         opacity: 1;
//     }

//     .tooltiptext_left {
//         visibility: visible;
//         opacity: 1;
//     }
// }