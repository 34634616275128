.ButtonModal {

    margin-bottom: 10px;
    .files_label {
        font-size: 14px;
        font-weight: 500;
        color: #70758D;

        &.uploaded {
            color: #FC5735
        }
    }

    .file_container {
        border: 1px solid #70758D;
        border-radius: 8px;
        height: 35px;
    }

    .add_vechile {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #FC5735;

        .vechile_heading {
            font-weight: 500;
            font-size: 10px;
        }

        .vechile_add {
            font-weight: 400;
            text-decoration: underline;
            cursor: pointer;
            font-size: 11px;
        }
    }

    .vechile_subHeading {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 12px;


        .vechile_count {
            color: #101941;
            font-weight: 500;
            padding: 10px 0px;
        }

        .vechile_remove {
            color: #FC5735;
            font-weight: 500;
            margin-left: 10px;
            font-size: 10px !important;
            text-decoration: underline;
            cursor: pointer;

        }
    }


}

.main_scroll_container{
    max-height: 200px;
    overflow: visible;
}

.flex_modal{
    &__date_container {
        input {
            width: 100%;
            outline: 0;
            border: 0;
            font-size: 11px;
            background: transparent;
        }

        input::placeholder {
            color: #B7BAC6 !important;
        }

        input[type="date" i]::-webkit-calendar-picker-indicator {
            cursor: pointer;
            background-image: url(../../assets/images/calendar.svg)
        }

    }

}
 .flex_cont {
    display: grid;
    align-items: center;
    grid-template-columns: 47% 47%;
    gap: 20px;
    }

    .visit_heading {
        margin-top: 10px;
        font-weight: 500;
            font-size: 10px;
            color: #FC5735;

    }
