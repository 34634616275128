// Variables for colours
$primary-orange: #FC5735;
$secondary-orange: #FC441E;
$gradiant-orange: linear-gradient(45deg, #FC441E 0%, #F94054 100%);
$primary-blue: #101941;
$secondary-blue: #404767;
$grey-blue: #70758D;
$base-grey: #B7BAC6;
$light-grey: #CFD1D9;
$light-blue: #E8E8EC;
$white: #fff;
$yellow: #EAA114;
$green: #12D377;
$red: #F94054;
$blue: #0430C0;
$dark-grey: #4A4A49;
$grey-3: #777776;
$grey-4: #A5A5A4;
$grey-5: #D2D2D1;
$light-grey: #F4F4F7;
$black: #1D1D1B;
$primary-orange-disabled: #FDB3B5;