@import "../../styles/mixins.scss";
@import "../../styles/variables.scss";

.list {
 
    .selectList {
    border: 1px solid $base-grey;
    max-height: 336px;
    width: 335px;
    overflow: auto;
    border-radius: 8px;
    margin-top: 10px;
}
&__bottom{
    top: 25px;
    right: 0;
    transition: 0.4s;
    height: fit-content;
    position: absolute;
    overflow: hidden;
    background-color: #f8f8f8;
    z-index: 1;
}
&__top{
    bottom: 25px;
    right: 0;
    transition: 0.4s;
    height: fit-content;
    position: absolute;
    overflow: hidden;
    background-color: #f8f8f8;
    z-index: 1;
}
}

.hide_list {
    position: absolute;
    transition: 0.4s;
    height: 0px;
    overflow: hidden;
}

.lists {
    padding: 5px 0px 5px 0px;
    cursor: pointer;
    color: $grey-blue;
    font-size: 12px;
    &:hover{
        background-color: $light-blue;
        color: $primary-blue;
        font-weight: 500;
    }

}


