.form_container{
    // position: relative;
    // top: -25px;
    max-height: 780px;
    // width: 871px;
    // overflow: hidden;
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      appearance: none;
      margin: 0;
    }

    // position: relative;
    // top:-31px;
    // display: grid;
    grid-template-rows: 38px 1fr;
    .form_header{
        grid-row: 1;
        margin-bottom: 18.5px;
        position: relative;
        // top: -7px;
        .form_heading{
            color : #101941;
            font-size: 15px;
            font-weight: 500;
        }
        .form_subheading{
            color : #FC5735;
            font-size: 10px;
            font-weight: 400;
        }
    }

     
    .form_body{
       grid-row: 2; 
       overflow: scroll;
      .page_container{
        display: flex;
        // flex-wrap: wrap;
        gap: 24px;
        max-height: 100%;
        overflow: scroll;
        &::-webkit-scrollbar {
            width: 0px; /* width of the scrollbar */
            height: 0px;
        }
        .page_section{
            width: 100%;
            .section_heading{
                color:#FC5735;
                font-size: 10px;
                font-weight: 500;
                line-height: 20px;
            }
            .form_section{
                margin-bottom: 18.5px;
                .section_header{
                    color : #101941;
                    font-size: 14px;
                    font-weight: 500;
                    margin-bottom: 10px;
                }
                .section_body{
                    // margin: 8px 0;
                    // input[type="radio"]{
                    //     width: 16px;
                    //     height: 16px;
                    //     margin-right: 6px;
                    // }

                    // .radio_container{
                    //     margin-right: 37px;
                    //     font-size: 13px;
                    // }
                    .download_excel{
                        border:1px solid #70758D;
                        border-radius: 6px;
                        height: 36px !important;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        a{
                            color: #70758D;
                            font-size: 14px;
                            font-weight: 500;
                            text-decoration: none;
                        }
                    }

                    .sub_section{
                        display: flex;
                        gap: 24px;
                    }
                    
                    .drop_file{
                        margin-top: 7px;
                        border: 1px dashed #B7BAC6;
                        height: 109px;
                        width: 400px;
                        border-radius: 8px;
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                        padding: 12px 0; 
                        .text{
                            font-size:10px ;
                            .subtext{
                                color: #0430C0;
                                text-decoration: underline;
                            }
                        }
                    }

                    .input_file_type {
                        input[type="file"] {
                          width: 100%;
                          opacity: 0;
                          cursor: pointer;
                          position: relative;
                          height: 60px;
                          top:-15px
                        //   @include space(margin, all, 10px, 0px);
                        }
                      
                        margin-top: 7px;
                        border: 1px dashed #B7BAC6;
                        height: 109px;
                        width: 400px;
                        border-radius: 8px;
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                        padding: 12px 0; 
                        .text{
                            font-size:10px ;
                            .subtext{
                                color: #0430C0;
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
            .hosting_address{
                height: 72px;
            }
        }
         
      }
    }

    .form_footer{
        position: relative;
        grid-row: 3;
        display: flex;
        justify-content: flex-end;
        gap:12px;
        align-items: center;
        position: relative;
        bottom:-40px;
        .cancel{
          background-color: #F9F9FA;
          color: #101941;
        }
        .save{
          background-color: #FC441E;
          color: #fff
        }
        .form_footer_button{
            width: 108px;
            height: 36px;
            border-radius: 6px;
            font-size: 15px;
            font-weight: 500;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            span{
                font-weight: normal;
                margin: 0 16px;
            }
        }
        .button_dropdown_container{
            position: absolute;
            top: -49px;
            width: 120px;
            height: 58px;
            background-color: #fff;
            border-radius: 4px;
            font-weight: normal;
            z-index: 99999999;
            padding: 12px 0;
            box-shadow: -2px 2px 4px 0px rgba(0, 0, 0, 0.08), 0px -2px 4px 0px rgba(0, 0, 0, 0.08);
            

            .button_dropdown_option{
                font-size: 14px;
                line-height: normal;
                padding-left: 12px
            }

            .button_dropdown_option:hover{
                background-color: #E8E8EC;
                border-left: 3px solid #70758D;
                font-weight: 500;
                cursor: pointer;
            }

            // display: flex;
            // flex-direction: column;
            // justify-content: center;   
        }
    }
}
.form_footer{
    position: relative;
    grid-row: 3;
    display: flex;
    justify-content: flex-end;
    gap:12px;
    align-items: center;
    position: relative;
    // bottom:-40px;
    .cancel{
      background-color: #F9F9FA;
      color: #101941;
    }
    .save{
      background-color: #FC441E;
      color: #fff
    }
    .form_footer_button{
        width: 108px;
        height: 36px;
        border-radius: 6px;
        font-size: 15px;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        span{
            font-weight: normal;
            margin: 0 16px;
        }
    }
    .button_dropdown_container{
        position: absolute;
        top: -49px;
        width: 120px;
        height: 58px;
        background-color: #fff;
        border-radius: 4px;
        font-weight: normal;
        z-index: 99999999;
        padding: 12px 0;
        box-shadow: -2px 2px 4px 0px rgba(0, 0, 0, 0.08), 0px -2px 4px 0px rgba(0, 0, 0, 0.08);
        

        .button_dropdown_option{
            font-size: 14px;
            line-height: normal;
            padding-left: 12px
        }

        .button_dropdown_option:hover{
            background-color: #E8E8EC;
            border-left: 3px solid #70758D;
            font-weight: 500;
            cursor: pointer;
        }

        // display: flex;
        // flex-direction: column;
        // justify-content: center;   
    }
}

.form_header{
    grid-row: 1;
    // margin-bottom: 18.5px;
    position: relative;
    top: -7px;
    .form_heading{
        color : #101941;
        font-size: 15px;
        font-weight: 500;
        position: relative;
        top: -15px;
    }
    .form_subheading{
        color : #FC5735;
        font-size: 10px;
        font-weight: 400;
    }
}

@media(max-width:767px){
    .page_container{
        flex-wrap: wrap;
    }
}

.insurance_text {
    color: #12D377;
    font-weight: 500;
    word-break: keep-all;
    &.not_req {
        color: #EAA114;
    }
    &.not_proc {
        color: #0430C0;
    }
}