@import "../../styles/variables.scss";
.drawer_backdrop{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(109 109 109 / 50%);
    z-index: 500;
}
.drawer {
    height: 100vh;
    width: 300px;
    background: $white;
    position: fixed;
    top: 0;
    right:0;
    z-index: 700;
    transition: 0.4s;
    overflow-x: hidden;
    overflow-y: hidden;
}
.close_drawer {
    height: 100vh;
    width: 0;
    background: $white;
    position: fixed;
    top: 0;
    right: -40px;
    z-index: 1;
    transition: 0.4s;
    overflow-x: hidden;
}