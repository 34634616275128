.success {
    padding: 15px 10px 15px 10px;
    // margin: 17px;
    background-color: #D0F6E5;
    border: 1.5px solid #12D377;
    border-radius: 10px;
    z-index: 999;
    max-width: 600px;
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    align-items: center;
    color: #101941;
    font-weight: 500;
    font-size: 15px;
}

.error {
    padding: 15px 10px 15px 10px;
    /* margin: 17px; */
    background-color: #FFD7DB;
    border: 1.5px solid #F94054;
    border-radius: 10px;
    z-index: 999;
    max-width: 600px;
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    align-items: center;
    color: #101941;
    font-weight: 500;
    font-size: 14px;
}
.img_mssg{
    width: 25px;
    margin-right: 7px;
    display: flex;
}