@import "../../styles/variables.scss";

.input_tag_wrapper {
    align-items: center;
    background: rgba(232, 232, 236, .2);
    border-bottom: 1px solid #e5e7eb;
    display: flex;
    // padding: 14px 10px;
    position: relative;
    width: 100%;
}

.input_tag_small_wrapper {
    width: 100px;
    border-radius: 8px;
    border: 1px solid $base-grey;
    position: relative;
    display: flex;
    // padding: 7px;
    align-items: center;

    input {
        border-radius: 8px;
        padding-left: 10px;
    }
}

.start_img {
    /* position: absolute;
    top: 10px;
    left: 3px; */
    margin: 0px 5px 0px 5px;
}

.input_tag {
    border: none;
    width: 100%;
    height: 30px;
    background: transparent;
    padding-left: 5px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 13px;
    letter-spacing: 0.005em;
    // text-transform: capitalize;
    color: #101941;
}

.input_tag::placeholder {
    color: $base-grey;
    position: absolute;
    // left: 20px;
}

.input_tag:focus {
    outline: none;
}

.end_img {
    margin: 0px 5px 0px 5px;
    height: 14px;
    width: 14px;
    /* position: absolute;
    right: 2px; */
    cursor: pointer;
}

.error {
    border: 1px solid $red !important;
    width: 100%;
    padding: 14px 10px 14px 10px;
    position: relative;
}
.error_text{
    color: $red !important;
    font-weight: 400;
    font-size: 10px;
    padding: 2px 0px 0px 5px;
}

// .input_tag_wrapper:focus-within {
//     border: 2px solid $primary-blue;
// }