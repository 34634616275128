@import "../../styles/mixins.scss";
@import "../../styles/variables.scss";


.notification_container {
    display: inline-block;
    z-index: 99;
    background-color: #fff;
    width: 400px;


    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1), -2px 2px 4px rgba(0, 0, 0, 0.1);
    ;
    position: absolute;
    top: 50px;
    right: 0px;
    border-radius: 6px;
    @include space(padding, top, 20px, 0px);

    section {
        .notification_container {

            &__wrapper {
                @include space(padding, left-right, 20px, 20px);

                &__header {
                    @include flex-space-between;
                    align-items: end;

                    &__markall {
                        @include font-size(11);
                        color: #70758D;
                        text-decoration: underline;
                        cursor: pointer;
                        display: inline-block;
                    }
                }
            }

            &__section {
                overflow-y: scroll;
                max-height: 500px;
                height: 500px;
            }
        }

    }

    .notification_tab_wrapper {
        @include space(margin, top, 15px, 0px);

        // width: 100px;
        @include flex;
        color: #101941;
        @include font-sizeImp(14);
        border-bottom: 1px solid #E8E8EC;

        .notification_tab_container {
            @include flex;
            align-items: start;

            .notification_tabs {
                display: inline-block;
                cursor: pointer;

                &:first-child {
                    @include space(margin, right, 10px, 0px);

                }

                &:hover {
                    font-weight: 500;
                }

                &__active {

                    &:first-child {
                        @include space(margin, right, 10px, 0px);

                    }

                    cursor: pointer;
                    font-weight: 500;
                    border-bottom: 2px solid #101941;
                    @include space(padding, bottom, 10px, 0px);

                }
            }
        }
    }

    &__lists {
        cursor: pointer;
        position: relative;
        @include space(padding, left-right, 20px, 20px);

        &__details {
            @include flex;
            align-items: start;
            @include space(padding, top-bottom, 12px, 12px);
            width: 100%;

            &__placeholder {
                @include flex-center;
                border-radius: 50%;
                width: 45px;
                height: 45px;
                color: #fff;
                @include font-size(16);
                font-weight: 500;
                background-color: #70758D;
            }

            &__section {
                @include space(margin, left-right, 15px, 0px);
                width: calc(100% - 45px);

                &__flex_between {
                    @include flex-space-between;
                }

                &__flex_end {
                    @include flex;
                    justify-content: end;
                }

                &__title {
                    display: inline-block;
                    @include space(margin, bottom, 10px, 0px);
                    font-size: 12px !important;
                    width: 97% !important;

                    &__name {
                        @include space(margin, right, 5px, 0px);
                        font-weight: 500;
                        font-size: 12px !important;
                    }
                }

                &__project_name {
                    @include font-sizeImp(12);
                    color: #70758D
                }

                &__separator {
                    @include space(margin, left-right, 5px, 5px);
                    @include font-size(12);

                }

                &__ticket {
                    @include font-sizeImp(12);
                    color: #70758D
                }

                &__date_time {
                    @include font-sizeImp(12);
                    color: #B7BAC6;
                }
            }
        }

        &__unread_strand {
            position: absolute;
            left: 0px;
            top: 0px;
            width: 3px;
            height: 100%;
            background-color: #fc441e4d;
        }

        &__unread_dot {
            position: absolute;
            right: 15px;
            top: 10px;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: #FC441E;
        }

        &:hover {
            background-color: rgba(#CFD1D9, 0.4); // Using rgba() function
        }

        &__border_top {
            border-top: 1px solid #E8E8EC;
        }

        &__border_bottom {
            border-bottom: 1px solid #E8E8EC;
        }

    }

    &__no_lists {
        @include flex-center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        flex-wrap: wrap;
        text-align: center;

        p {
            width: 100%;
            font-weight: 600;
            @include font-sizeImp(16);
            color: #404767;
        }

        svg {
            @include space(margin, top-bottom, 25px, 25px);

        }

        span {
            display: inline-block;
            width: 100%;
            @include font-size(14);
            color: #404767;

        }
    }
}

@media(max-width:767px){
    .notification_container{
        max-width: 90vw;
    } 
}