.wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    
    .container {
        display: flex;
        flex-direction: column;

        .image_container {
            text-align: center;
        }

        .text_section {
            text-align: center;
            font-size: 20px;
            line-height: 25.35px;
            color: rgba(16, 25, 65, 1);

            .bold_section {
                font-size: 24px;
                font-weight: 500;
                line-height: 30.42px;
                margin: 20px 0px;
            }
        }

        .button_container {
            display: flex;
            justify-content: center;

            .reload_button {
                background-color: transparent;
                color: rgba(253, 124, 98, 1);
                border: 2px solid rgba(253, 124, 98, 1);
                font-size: 16px;
                font-weight: 500;
                line-height: 20px;
                border-radius: 9px;
                margin-top: 30px;

                &.redirect_button {
                    width: 220px;
                }
            }
        }

        .error_container {
            margin-top: 30px;
            background-color: rgb(233, 234, 240);
            padding: 20px;
            max-height: 300px;
        }
    }

    .back_link {
        position: absolute;
        text-decoration: underline;
        bottom: 50px;
        text-underline-offset: 5px;
        cursor: pointer;
    }
}
