@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.scm {
    &__column {
        position: relative;
        // @include space(padding, top-bottom, 10px, 10px);
        @include space(padding, left-right, 5px, 10px);
        @include font-size(12);
        @include flex-left;

        &.pointer {
            cursor: pointer;
        }

        &__title {
            position: relative;
            @include flex-center;

            a {
                cursor: pointer;
                @include font-size(10);
                @include flex-center;
                color: #70758D;
                border-radius: 50%;
                // border: 1px solid #70758D;
                text-decoration: none !important;
                color: #fff !important;
                height: 24px;
                width: 24px;
                font-weight: 500;
            }

            p {
                font-weight: 600;
                color: #101941;
            }
        }
    }

    &__chargers {
        display: flex;
        justify-content: space-between;
        width: 35px;
        text-align: center;
        align-items: end;
    }

    &__view {
        text-decoration: underline;
        color: #0430C0;
        cursor: pointer;
    }

    &__menu {
        position: absolute;
        width: 250px;
        left: -120%;
        top: 30%;
        background: white;
        box-shadow: 0px 0px 8px 0px #d2d2d1;
        border-radius: 5px;
        min-height: 100px;
        z-index: 600;

        &__scope_title {
            color: #FD7C62;
            @include font-size(14);
            @include flex-space-between;
            @include space(padding, top-bottom, 10px, 0px);
            @include space(padding, left-right, 10px, 7px);
        }

        &__title {
            color: #101941;
            font-weight: 500;
            @include font-size(14);
            @include flex-space-between;
            @include space(padding, top-bottom, 10px, 0px);
            @include space(padding, left-right, 0px, 7px);
            position: sticky;
            top: 10px;
            background: white;

            &__icon {
                svg {
                    height: 12px
                }
            }
        }

        &__description {
            @include space(padding, all, 10px, 0px);
            color: #404767;

            div {
                @include flex-space-between;
                @include font-size(12);
                @include space(padding, top-bottom, 5px, 5px);

                span {
                    font-weight: 400;
                    width: 50%;

                    &:last-child {
                        font-weight: 500;
                        @include font-size(14);

                    }
                }
            }
        }
    }

    &__menu_backdrop {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: transparent;
        z-index: 500;
    }
}

.user_name {
    white-space: nowrap
}

.view_more {
    color: #0430C0;
    text-decoration: underline;
    cursor: pointer;
    font-size: 12px;
    margin-left: 3px;
}

.more_display {
    margin: 10px 5px;
    word-break: break-all;
}

.view {
    // border-right: 1px solid #0430C0;
    padding-right: 10px;
    margin-right: 6px;
    color: #0430C0;
    text-decoration: underline;
    cursor: pointer;

}

.center_data {
    justify-content: center;
    text-align: center;
}

.agedata_heading {
    font-weight: 500;
    font-size: 14px;
    width: 49%;
    margin-bottom: 10px;
}

.ageData {
    font-size: 12px;
    width: 49%;
}