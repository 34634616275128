* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'MarkPro';
  font-size: 100%;
  /* Sets up the Base 10 stuff */
}

body {
  background-color: #f5f5f5;
}

button {
  font-family: 'MarkPro';
}

input {
  @include font-size(14);
  background-color: $white;
}

input[type="checkbox"] {
  accent-color: $primary-blue;
}

input[type="radio"] {
  accent-color: $primary-blue;
  cursor: pointer;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

h1 {
  @include font-sizeImp(30);
}

h2 {
  @include font-sizeImp(25);
}

h3 {
  @include font-sizeImp(18);
}

h4 {
  @include font-sizeImp(12);
}

h5 {
  @include font-sizeImp(10);
}

p {
  @include font-sizeImp(14);
  color: $primary-blue;
}


.f-s-40 {
  @include font-sizeImp(40);
}

.f-s-20 {
  @include font-sizeImp(20);
}

.f-s-15 {
  @include font-sizeImp(15);
}

.f-s-12 {
  @include font-sizeImp(12);
}

.f-s-10 {
  @include font-sizeImp(10);
}

.flex {
  display: flex;
  &.gap-20 {
    gap: 20px;
  }
}
.flex-between{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-center {
  display: flex;
  align-items: center;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.loader-container {
  height: 68vh;
  position: relative;
  background-color: #fff;

  &.center {
    @include flex-center;
  }

  div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 350px;
    height: 200px;

    img {
      height: 200px;
    }

    span {
      color: #70758D;
      @include font-size(16);
    }
  }

}

.center {
  @include flex-center;
}

.nav-icon {
  font-size: 1.5rem;
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  margin-bottom: 2px;
}

.container {
  display: grid;
  grid-gap: 1px;
  grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
  grid-template-rows: repeat(auto-fit, minmax(30rem, 1fr));
}

.container__content {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100vh;
  overflow-x: auto;
}

.padded-container {
  padding: 20px;

  @include breakpoint(Ipad) {
    @include space(padding, all, 15px, 0px);
  }

  @include breakpoint(desktop1025) {
    @include space(padding, all, 15px, 0px);
  }
}

.files_label {
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #70758D !important;
  width: max-content;

  &.uploaded {
    color: #FC5735 !important;
  }
}

.file_container {
  border: 1px solid #70758D !important;
  border-radius: 8px !important;
  height: 35px !important;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    height: inherit;
    text-decoration: none !important;
  }

  .file_container_label {
    position: unset !important;
    transform: none !important;
  }
}

.input_file_type {
  display: inline-block;
  width: 100%;
  border: 1px solid #FC5735;
  color: #FC5735;
  background-color: #fff;
  // @include space(padding, all, 10px, 0px);
  border-radius: 5px;
  position: relative;

  &.error {
    border: 1px solid red !important;
  }

  input[type="file"] {
    width: 100%;
    opacity: 0;
    cursor: pointer;
    @include space(margin, all, 10px, 0px);
  }

  label {
    @include font-size(20);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.file_uploaded {
  color: #FC5735 !important;
}

.input_file_type_images {
  display: inline-block;
  width: 100px;
  height: 100px;
  border: 1px solid #B7BAC6;
  color: #B7BAC6;
  background-color: #fff;
  // @include space(padding, all, 10px, 0px);
  border-radius: 5px;
  position: relative;

  input[type="file"] {
    width: 100%;
    z-index: 1;
    opacity: 0;
    height: 100%;
    cursor: pointer;
    // @include space(margin, all, 10px, 0px);
  }

  label {
    cursor: pointer;
    @include font-size(16);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

// /* width */
// ::-webkit-scrollbar {
//   width: 5px;
//   height: 5px;
// }

// /* Track */
// ::-webkit-scrollbar-track {
//   // box-shadow: inset 0 0 5px #c3c3c3;
//   border-radius: 0px;
// }

// /* Handle */
// ::-webkit-scrollbar-thumb {
//   background: $light-grey;
//   border-radius: 0px;
// }

// /* Handle on hover */
// ::-webkit-scrollbar-thumb:hover {
//   background: $light-grey;
// }

/* ===== Scrollbar CSS ===== */
/* Firefox */
*::-webkit-scrollbar {
  width: 0px; /* width of the scrollbar */
  height: 0px;
}

/* Chrome, Edge, and Safari */
// *::-webkit-scrollbar {
//   width: 10px;
//   height: 5px;
// }

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #f2f2f2;
  // border-right-width: 5px;
  // border-bottom-width: 5px;

  border: 4px solid #ffffff;
}

.text-capitalize {
  text-transform: capitalize;
}

.width-100p {
  width: 100%;
}

.width-58p {
  width: 58%;
}

.mr-5 {
  margin-right: 5px
}

.bulk-label-btn {
  color: $primary-orange;
  border: 1px solid $primary-orange;
  background: $white;
  border-radius: 5px;
  padding: 6px 8px 4px 8px;
  height: 30px;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;

  &.filled {
    background: $primary-orange;
    color: $white;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
} 

.select_all_header {
  display: flex;
  gap: 6px;
  align-items: center;
}

.skeleton-loader {
  width: 100%;
  height: 20px;
  background: linear-gradient(90deg, #f4f4f4 25%, #eaeaea 50%, #f4f4f4 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
  border-radius: 8px;
}

.text-decoration-link {
  text-decoration: underline;
  color: #0000EE;
  cursor: pointer;
}

@keyframes shimmer {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
