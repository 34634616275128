@import "../../styles/mixins.scss";
@import "../../styles/variables.scss";


.list {
    position: relative;
    //  top: 50px;
    //  left: 0;
    width: inherit;
 
    background-color: #fff;
    
    .selectList {
        // border: 2px solid #101941;
        max-height: 260px;
        // height: 200px;
        background-color: #fff;
        // position: absolute;
        z-index: 99;
        width: 100%;
        overflow: auto;
        // border-radius: 8px;
        // padding: 20px;
        margin-top: 5px;

        li {
            @include flex;
            padding-left: 10px;
            padding-bottom: 10px;
            padding-top: 10px;
            cursor: pointer;
            position: relative;
            &:first-child {
                padding-top: 10px;
            }

            &:last-child {
                padding-bottom: 10px;
            }

            list-style: none;

            input {
                width: 20px;
                height: 20px;
                border-radius: 6px;
                margin-right: 10px;
            }

            .list_name {
                color: #404767;
                cursor: pointer;
            }
            &:hover{
                cursor: pointer;
                background-color: #E8E8EC;
                &::before{
                    content: "";
                    position: absolute;
                    width: 3px;
                    height: 100%;
                    background-color: #70758D;
                    left: 0;
                    top: 0;
                }
            }
        }
        &__title {
            @include flex-left;
    
            span {
                @include font-size(12);
                @include space(margin, right, 10px, 0);
                @include flex-center;
                color: #70758D;
                border-radius: 50%;
                // border: 1px solid #70758D;
                text-decoration: none !important;
                color: #fff !important;
                cursor: default !important;
                height: 32px;
                width: 32px;
                font-weight: 500;
            }
    
            p {
                font-weight: 600;
                color: #101941;
            }
        }

    }

    &__bottom {
        top: 25px;
        right: 0;
        transition: 0.4s;
        height: fit-content;
        position: absolute;
        overflow: hidden;
        background-color: #f8f8f8;
        z-index: 1;
    }

    &__top {
        bottom: 25px;
        right: 0;
        transition: 0.4s;
        height: fit-content;
        position: absolute;
        overflow: hidden;
        background-color: #f8f8f8;
        z-index: 1;
    }
}

.hide_list {
    position: absolute;
    transition: 0.4s;
    height: 0px;
    overflow: hidden;
}

.lists {
    padding: 5px 0px 5px 0px;
    cursor: pointer;
    color: $grey-blue;
    font-size: 12px;

    &:hover {
        background-color: $light-blue;
        color: $primary-blue;
        font-weight: 500;
    }

}

.input_tag_wrapper {
    width: 100%;
    height: 30px;
    border-radius: 2px;
    border-bottom: 1px solid $base-grey;
    background-color: rgba(232,232,236,.2);
    position: relative;
    display: flex;
    padding: 14px 10px 14px 10px;
    align-items: center;

}

.start_img {
    /* position: absolute;
    top: 10px;
    left: 3px; */
    margin: 0px 5px 0px 5px;
    &__title {
        @include flex-left;

        span {
            @include font-size(12);
            @include space(margin, right, 10px, 0);
            @include flex-center;
            color: #70758D;
            border-radius: 50%;
            // border: 1px solid #70758D;
            text-decoration: none !important;
            color: #fff !important;
            cursor: default !important;
            height: 32px;
            width: 32px;
            font-weight: 500;
        }

        p {
            font-weight: 600;
            color: #101941;
        }
    }
}

.input_tag {
    width: 100%;
    flex: 1 1;
    outline: none;
    font-size: 10px;
    border: none;
    color: #404767;
    font-family: "MarkPro";
    background: transparent;
}
.error_box{
    border: 1px solid $red !important;
}
.input_tag::placeholder {
    color: $base-grey;
    position: absolute;
    left: 0%;
}

.end_img {
    margin: 0px 5px 0px 5px;
    /* position: absolute;
    right: 2px; */
    text-decoration: none !important;
    display: inline-block;
    cursor: pointer !important;
    color: #B7BAC6 !important;
    font-weight: 500 !important;
    font-size: 20px;
}

.error {
    border: 1px solid $red;
    width: 100%;
    border-radius: 8px;
    padding: 14px 10px 14px 10px;
    position: relative;
    display: flex;
    align-items: center;
}

// .input_tag_wrapper:focus-within {
//     border: 1px solid #70758D;
// }