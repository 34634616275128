.action_cell {
    display: flex;
    gap: 8px;
}

.tooltip_container {
    top: 3px;
}

.charger_icon_container {
    display: flex;
    align-items: center;
    gap: 5px;
}
.table_container{
    height: 85%;
    overflow-y: auto;
}
@media(max-width:767px){
    .table_container{
        border: 1px solid #E5E7EB;
        margin-top: 10px;
    }
}