@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.accordion_container {
    @include space(padding, bottom, 5px, 0);

    .accordion_content {
        .accordion_item {
            .accordion_title {
                background-color: #F4F4F7;
                border-radius: 5px;
                @include flex-space-between;
                // @include space(padding, all, 15px, 0);
                cursor: pointer;

                &__text {
                    @include font-size(14);
                    font-weight: 500;
                    color: $primary-blue;
                }

                span {
                    cursor: pointer;
                }
            }

            .accordion_content {
                @include space(padding, all, 15px, 0);
                @include flex-space-between;
                @include font-size(12);
                color: #404767;
                flex-wrap: wrap;

                // div {
                //     @include flex-space-between;
                //     @include space(padding, right, 5px, 0px);

                //     label {
                //         @include space(padding, left-right, 10px, 10px);
                //     }
                // }
            }
        }
    }
}