@import "../../styles/mixins.scss";
@import "../../styles/variables.scss";

.pagination_view {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0px;

    // @include space(padding, top-bottom, 60px, 30px);
    // @include space(padding, left-right, 40px, 40px);
    // @include breakpoint(desktop){
    //     @include space(padding, top-bottom, 40px, 30px);
    // }
    // @include breakpoint(desktop1280){
    //     @include space(padding, top-bottom, 40px, 30px);
    // }
    // @include breakpoint(desktop1025){
    //     @include space(padding, top-bottom, 30px, 15px);
    // }
    // @include breakpoint(Ipad){
    //     @include space(padding, top-bottom, 20px, 30px);
    // }
    .pages_list {
        @include flex-center;
        position: relative;

        div {
            width: 50px;
            height: 18px;
            @include space(padding, left-right, 7px, 7px);
            @include space(padding, top-bottom, 7px, 7px);
            border-radius: 2px;

            input {
                width: 100%;
                color: #70758D;
                font-weight: 500;
                outline: 0;
                border: none;
                @include space(padding, top, 2px, 0px);
                @include font-size(10);
                cursor: pointer;

                &:focus-within {
                    outline: 0;
                    border: none;
                }
            }

            img {
                margin: 0;
            }
        }

        span {
            width: 50px;
            text-align: center;
            max-height: 336px;
            // top: 25px;
            // right: 0;

            ul {
                width: 50px;
                text-align: left;
                height: fit-content;
                border-radius: 2px;
                margin-top: 2px;

                li {
                    @include space(padding, left-right, 5px, 5px);
                }
            }
        }
    }

    .pagination_content {
        text-align: right;
        font-size: 32px;
        @include flex-center;
        position: fixed;
        right: 46px;
    }

    .pointer_cursor {
        cursor: pointer;
    }

    .activePage {
        cursor: pointer;
        padding: 7px;
        font-weight: 700;
    }

    .inactivePage {
        cursor: pointer;
        padding: 7px;
        font-weight: 400;
    }

    .imagePadding {
        display: flex;
        padding: 5px
    }

}

.pagination_content {
    text-align: right;
    font-size: 32px;
}

.pointer_cursor {
    cursor: pointer;
}

.activePage {
    cursor: pointer;
    padding: 7px;
    font-weight: 700;
}

.inactivePage {
    cursor: pointer;
    padding: 7px;
    font-weight: 400;
}

.imagePadding {
    padding: 5px
}

.flex {
    display: flex;
    align-items: center;
}

.selectText {
    margin-right: 5px;
    font-size: 12px;


}

.mweb_pagination_container {
    display: flex;
    align-items: center;
    justify-content: end;
    margin: 10px 0;
    border-radius: 2px;
}

.current_page {
    width: 22.06px;
    height: 22.06px;
    background-color: #E8E8EC;
    color: #404767;
    font-size: 12px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px;
}

.prev_container,
.next_container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
        margin: 0 5px;
    }
}

.showing_text {
    font-size: 12px;
    font-weight: 500;
    color: #B7BAC6;
    margin-right: 14px;
}