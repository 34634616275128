.main_container {
  background: white;
  padding: 10px;
  height: 350px;
  width: 220px;
  overflow: auto;
  max-height: 400px;
  min-width: 200px;
  max-width: 270px;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 15px;
}

.creatableMultiselect {
  position: relative;
  width: 100%;
  max-width: 300px;
  margin-top: 4px;
  color: #101941;

  .inputWrapper {
    max-height: 185px;
    background: #E8E8EC33;

  }

  .selectedItems {
    display: flex;
    flex-wrap: wrap;
    pointer-events: none;
  }

  .chipContainer {
    overflow-y: auto;
    max-height: 105px;
    width: 88%;
  }

  .selectedItem {
    background-color: #e0e0e0;
    padding: 4px 8px;
    border-radius: 16px;
    margin: 4px;
    display: flex;
    align-items: center;
    font-size: 10px;
    pointer-events: auto;

    button {
      background: none;
      border: none;
      cursor: pointer;
      margin-left: 6px;
      font-size: 14px;
    }
  }

  .inputField {
    width: 100%;
    padding: 8px;
    border: none;
    font-size: 10px;
    position: relative;
    z-index: 0;
    padding-left: 8px;
    background: #E8E8EC33;

    &:focus {
      outline: none;
      border-color: #007bff;
    }

    &::placeholder {
      color: #70758D;
    }
  }

  .clearAll {
    cursor: pointer;
    pointer-events: auto;
    width: 14px;
    padding-top: 8px;
  }

  .chip_clear_cont {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    background: rgba(232, 232, 236, 0.2);
    width: 100%;

  }

  .optionsList {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    max-height: 150px;
    overflow-y: auto;
    position: absolute;
    background: white;
    width: 100%;
    z-index: 10;
    top: 100%;
    margin-top: 6px;

    .all_label {
      font-size: 10px;
      padding: 8px;
      font-weight: 500;
    }

    .optionItem {
      padding: 8px;
      cursor: pointer;
      font-size: 10px;
      color: #101941;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:hover {
        background-color: #e8e8ec;
        border-left: 3px solid #70758d;
        border-radius: 0px;
      }
    }

    .selectedItemInOptions {
      padding: 8px;
      cursor: default;
      background: #e8e8ec;
      color: #666;

      &:hover {
        background-color: #e8e8ec;
      }
    }

    .createOption {
      padding: 8px;
      cursor: pointer;
      font-size: 10px;
      color: #101941;
      background-color: #e8e8ec;
      border-left: 3px solid #70758d;
      border-radius: 0px;
    }
  }

  .errorMessage {
    color: rgba(249, 64, 84, 1);
    font-size: 10px;
    margin-top: 8px;
    padding: 4px;
    padding-bottom: 0px;
    border-radius: 4px;
  }
}