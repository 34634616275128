@import "../../../styles/mixins.scss";
@import "../../../styles/variables.scss";

$standard-size: 16px;
$icon-size: 6px;
$font-weight: 400;

.label_container {
    display: grid;
    grid-template-columns: 28% auto auto auto 10%;
    gap: 0.5rem;
    align-items: flex-start;
}

.label {
    &__container {
        display: flex;
        gap: 4px;
        flex-wrap: wrap;
        position: relative;

        span {
            font-weight: $font-weight !important;
        }


        .add_label {
            position: absolute;
            top: 20px;
            left: 0;
            padding: 8px;
            border: none;
            border-radius: 4px;
            z-index: 10;
            cursor: pointer;
        }
    }

    &__chip {
        @include flex-center;
        height: $standard-size;
        border: 0.5px solid $secondary-blue;
        color: $secondary-blue;
        width: fit-content;
        border-radius: 8px;
        padding: 8px;
        font-size: 10px;
        line-height: 14px;
        position: relative;
        background-color: #f0f0f0;

        .clearIcon {
            font-size: 6px;
            color: rgba(64, 71, 103, 1);
            cursor: pointer;
            visibility: hidden;
            position: absolute;
            top: 2px;
            right: 3px;
            &:hover {
                font-weight: 500;
            }
        }

        &:hover .clearIcon {
            visibility: visible;
            /* Show the cross icon on hover */
        }

        span {
            text-wrap-mode: nowrap;
        }
    }
    &__showClear_style {
        &:hover {
            padding: 8px 12px 8px 8px !important;
            font-weight: 500 !important;
        }
    }


}

.tooltip {
    position: relative;
    display: inline-block;
    top: -2px;
}
.label_tooltip{
    display: grid;
    grid-template-columns: auto;
     gap: 1rem;
     font-size: 10px;
}

.tooltip .tooltiptext {
    visibility: true;
    width: 134px;
    background-color: #FFFFFF;
    color: $secondary-blue;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 2;
    box-shadow: 0 -2px 4px rgba(0, 0, 0, .08), -2px 2px 4px rgba(0, 0, 0, .08);
    top: 110%;
    left: 180%;
    margin-left: -60px;
}

.tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    left: 180%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    box-shadow: 0 10px 10px -17px rgba(0, 0, 0, 0.8);
    border-color: transparent transparent #FFFFFF transparent;
}