@font-face {
    font-family: 'MarkPro';
    src: local("MarkPro"),
      url('../utils/Font/MarkPro.ttf') format('truetype');
    font-weight: normal;
    font-size: 14px;
  }
  
  @font-face {
    font-family: 'MarkPro';
    src: local("MarkPro"),
      url('../utils/Font/MarkProMedium.ttf') format('truetype');
    font-weight: 500;
  }