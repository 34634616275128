@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.button_large {
    width: 335px;
    height: 52px;
    background: $primary-orange;
    border-radius: 7px;
    border: none;
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
}

.button_medium {
    width: 185px;
    height: 52px;
    background: #fff;
    border-radius: 7px;
    border: 1px solid $primary-orange;
    color: $primary-orange;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
}

.button_small {
    width: 185px;
    height: 40px;
    background: $primary-orange;
    border-radius: 7px;
    border: none;
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;

    @include breakpoint(desktop1025) {
        height: 35px;
        width: 150px;
        @include font-size(18)
    }
}

.button_small_elongated {
    width: 132px !important;
    height: 30px;
    background: $primary-orange;
    border-radius: 7px;
    border: none;
    color: #fff;
    font-size: 14px;
    margin: 0 5px;
    // font-weight: 500;
    cursor: pointer;

    @include breakpoint(desktop1025) {
        height: 30px;
        width: 130px;
        // @include font-size(18)
    }
}

.button_small_elongated_gray {
    width: 132px;
    height: 30px;
    background: #F9F9FA;
    border-radius: 7px;
    border: none;
    color: #101941;
    font-size: 15px;
    margin: 0 5px;
    // font-weight: 500;
    cursor: pointer;

    @include breakpoint(desktop1025) {
        height: 30px;
        width: 132px;
        // @include font-size(18)
    }
}

.button_small_outline {
    width: 185px;
    height: 40px;
    background: #fff;
    border-radius: 7px;
    border: 1px solid $primary-blue;
    color: $primary-blue;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
}

.button_exsmall {
    width: 155px;
    height: 40px;
    background: $primary-orange;
    border-radius: 7px;
    border: none;
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
}

.button_exsmall_outline {
    width: 155px;
    height: 40px;
    background: #fff;
    border-radius: 7px;
    border: 1px solid $primary-blue;
    color: $primary-blue;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
}

.button_large:hover {
    background: $gradiant-orange;
}


.button_medium:hover {
    background: linear-gradient(45deg, rgba(252, 68, 30, 0.2) 0%, rgba(249, 64, 84, 0.2) 100%);
}

.button_small:hover {
    background: $primary-orange;
    border-radius: 7px;
    border: none;
    color: #fff;
    font-weight: 500;
    cursor: pointer;
}

.button_small_outline:hover {
    background: #fff;
    border-radius: 7px;
    border: 1px solid $primary-blue;
    color: $primary-blue;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
}

.button_exsmall:hover {
    width: 155px;
    height: 40px;
    background: $primary-orange;
    border-radius: 7px;
    border: none;
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
}

.button_exsmall_outline:hover {
    width: 155px;
    height: 40px;
    background: #fff;
    border-radius: 7px;
    border: 1px solid $primary-blue;
    color: $primary-blue;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
}

.button_large:disabled {
    background: $primary-orange-disabled;
}

.button_medium:disabled {
    border: 1px solid $primary-orange-disabled;
    color: $primary-orange-disabled;
    background: $white;

}