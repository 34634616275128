@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.custom_modal {

    // position: absolute;
    // width: 250px;
    // left: 0;
    // top: 30%;
    // background: white;
    // box-shadow: 0px 0px 8px 0px $grey-5;
    // border-radius: 5px;
    // min-height: 100px;
    // z-index: 600;
    // transition: 0.5s all;
    &__autocomplete {
        opacity: 0;

        &__right {
            transition: opacity 2000ms ease-in-out;
            opacity: 1;
            // right: 25%;
            position: absolute;
            width: 250px;
            // top: 100%;
            top: 50%;
            transform: translate(-90%, 25%);
            background: white;
            box-shadow: 0px 0px 8px 0px $grey-5;
            border-radius: 5px;
            z-index: 600;
        }

        &__left {
            transition: opacity 2000ms ease-in-out;
            opacity: 1;
            position: absolute;
            width: 250px;
            // left: 0;
            top: 50%;
            transform: translate(-90%, 5%);
            // top: 30%;
            background: white;
            box-shadow: 0px 0px 8px 0px $grey-5;
            border-radius: 5px;
            z-index: 600;
        }

        &__top {
            transition: opacity 2000ms ease-in-out;
            opacity: 1;
            // bottom: 25px;
            bottom: 100%;
            transform: translate(-90%, 0%);
            position: absolute;
            width: 250px;
            background: white;
            box-shadow: 0px 0px 8px 0px $grey-5;
            border-radius: 5px;
            z-index: 600;
            // max-height: 120px;
            overflow: auto;
        }

        &__bottom {
            transition: opacity 2000ms ease-in-out;
            position: absolute;
            width: 250px;
            // bottom: 0;
            top: 50%;
            transform: translate(-40%, 25%);
            opacity: 1;
            background: white;
            box-shadow: 0px 0px 8px 0px $grey-5;
            border-radius: 5px;
            z-index: 600;
            // max-height: 120px;
            overflow: auto;
        }

        &__backdrop {
            position: fixed;
            width: 100%;
            max-height: 100%;
            top: 0;
            left: 0;
            background: transparent;
            z-index: 500;
        }

        &__container {


            &__title {
                color: #FD7C62;
                @include font-size(14);
                @include flex-space-between;
                border-bottom: 1px solid #E8E8EC;
                @include space(padding, top-bottom, 10px, 0px);
                @include space(padding, left-right, 10px, 7px);

                &__icon {
                    svg {
                        height: 12px
                    }
                }
            }

            &__description {
                @include space(padding, all, 10px, 0px);

                div {
                    @include flex-space-between;
                    @include font-size(12);
                    @include space(padding, top-bottom, 5px, 5px);

                    span {
                        font-weight: 400;
                        width: 50%;

                        &:last-child {
                            font-weight: 500;
                            @include font-size(14);

                        }
                    }
                }
            }
        }
    }

    opacity: 0;

    &__right {
        transition: opacity 2000ms ease-in-out;
        opacity: 1;
        right: 0;
        position: absolute;
        width: 250px;
        top: 30%;
        background: white;
        box-shadow: 0px 0px 8px 0px $grey-5;
        border-radius: 5px;
        min-height: 100px;
        z-index: 600;
        max-height: 120px;
            overflow: auto;
    }

    &__left {
        transition: opacity 2000ms ease-in-out;

        position: absolute;
        width: 250px;
        left: 0;
        top: 30%;
        background: white;
        box-shadow: 0px 0px 8px 0px $grey-5;
        border-radius: 5px;
        min-height: 100px;
        z-index: 600;
        max-height: 120px;
            overflow: auto;
    }

    &__top {
        transition: opacity 2000ms ease-in-out;
        opacity: 1;
        bottom: 25px;
        position: absolute;
        width: 250px;
        background: white;
        box-shadow: 0px 0px 8px 0px $grey-5;
        border-radius: 5px;
        min-height: 100px;
        z-index: 600;
        max-height: 120px;
            overflow: auto;
    }

    &__bottom {
        transition: opacity 2000ms ease-in-out;
        position: absolute;
        width: 250px;
        bottom: 0;
        background: white;
        box-shadow: 0px 0px 8px 0px $grey-5;
        border-radius: 5px;
        min-height: 100px;
        z-index: 600;
        max-height: 120px;
            overflow: auto;
    }

    &__backdrop {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: transparent;
        z-index: 500;
    }

    &__container {


        &__title {
            color: #FD7C62;
            @include font-size(14);
            @include flex-space-between;
            border-bottom: 1px solid #E8E8EC;
            @include space(padding, top-bottom, 10px, 0px);
            @include space(padding, left-right, 10px, 7px);

            &__icon {
                svg {
                    height: 12px
                }
            }
        }

        &__description {
            @include space(padding, all, 10px, 0px);

            div {
                @include flex-space-between;
                @include font-size(12);
                @include space(padding, top-bottom, 5px, 5px);

                span {
                    font-weight: 400;
                    width: 50%;

                    &:last-child {
                        font-weight: 500;
                        @include font-size(14);

                    }
                }
            }
        }
    }
}