@import "../../styles/mixins.scss";
@import "../../styles/variables.scss";

.circular_loader_container {
  @include flex-space-around;

  .circular_loader {
    border: 4px solid #fff;
    border-radius: 50%;
    border-top: 4px solid #fdb3b5;
    width: 30px;
    height: 30px;
    animation: spinner 2s linear infinite;

    @keyframes spinner {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }
  }
}