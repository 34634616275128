.container{
    width: 100%;
    border-radius: 6px;
    border: 1px solid #70758D;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 12px 10px 12px;
    cursor: pointer;
    
    .title{
        font-weight: 500;
        font-size: 14px;
        color: #70758D;
    }

    
}

.subtitle{
    margin-top: 3px;
    color: #70758D;
    font-size: 10px;
}


.download_excel{
    position:relative;
    border:1px solid #70758D;
    border-radius: 6px;
    height: 36px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    span{
        color: #70758D;
        font-size: 14px;
        font-weight: 500;
        text-decoration: none;
    }
}
.file_upload_text {
    color: #FC441E !important;
    font-weight: 500 !important;
    font-size: 10px !important;

}