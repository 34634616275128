.container {
    display: flex;
    gap: 5px;
    width: 100%;
    .progressbar_container {
        min-width: 66px;
        width: 100%;
        // height: 6px;
        position: relative;
        overflow: hidden;
        border-radius: 7.5px;
        top: 2px;
        .progressbar_filled{
            position: absolute;
            height: 100%;
            border-radius: 7.5px;
        }
    }

    .progressbar_value {
        color: #101941;
        font-size: 10px;
        font-weight: 500;
        letter-spacing: 1px;
    }
}