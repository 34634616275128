.no_data_container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 40px;
    .image{
        width: 504px;
        height: 291px;
    }
}

@media(max-width:767px){
    .no_data_container{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-top: 40px;
        .image{
            width: 100%;
            height: auto;
        }
    }
}