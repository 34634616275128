@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.loader_container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.table_id {
    text-decoration: underline;
    color: #0430c0;
    font-weight: 500;
    cursor: pointer;
}

.table_history {
    border: 1px solid #e5e7eb;
    height: 100%;
    padding: 10px 15px;
}

.table_container {
    // max-height: 90%; /* Set the maximum height for the table container */
    overflow-y: auto;

    .table {
        // margin-top: 20px;
        width: 100%;
        // height: 100%;
        align-items: center;
        border-collapse: collapse;

        .table_head {
            position: sticky;
            top: 0px;
            // z-index: 100;
            background-color: #ffffff;
            // z-index: 99;

            .table_head_row {
                .table_head_data {
                    width: 150px !important;
                    min-width: 60px;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 11px;
                    line-height: 14px;
                    text-transform: capitalize;
                    color: #70758d;
                    text-align: left;
                    padding: 5px 0 10px 10px;

                    &.has_sort_arrows {
                        display: flex;
                        align-items: center;
                    }

                    .sort_arrows {
                        display: flex;
                        flex-direction: column;
                        margin-left: 5px;

                        .dsc_arrow {
                            transition: transform 0.3s ease-in-out;

                            &.down {
                                transform: rotate(180deg);
                            }

                            &.up {
                                transform: rotate(0deg);
                            }
                        }

                        .up_arrow,
                        .down_arrow {
                            cursor: pointer;
                            fill: #70758d;
                            margin: -3.5px 0;
                        }

                        span {
                            cursor: pointer;
                        }
                    }
                }
            }
        }

        .table_body {
            overflow-y: scroll;

            .table_row {
                .table_row_data {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 15px;
                    align-items: center;
                    color: #101941;
                    // padding-top:12px;
                    // padding-bottom:12px;
                    padding: 12px 0 12px 10px;

                    .text_tooltip {
                        display: flex;
                    }

                    .status {
                        font-weight: 500;
                        font-size: 12px;
                        border-radius: 30px;
                        padding: 4px;
                        display: flex;
                        text-align: center;
                        height: 32px;
                        justify-content: center;
                        align-items: center;
                        width: 89px;
                    }

                    .progress_container {
                        width: 100%;

                        .status_text {
                            font-size: 12px;
                            font-weight: 500;
                            line-height: 15px;
                            color: #101941;
                        }

                        .progress_bar {
                            width: 100%;
                        }
                    }

                    //data types
                    .underline {
                        text-decoration: underline;
                        color: rgb(4, 48, 192) !important;
                        cursor: pointer;
                    }

                    .truncated_text {
                        text-overflow: ellipsis;
                        overflow: hidden;
                        width: 12ch;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        line-clamp: 2;
                    }

                    .rejected_underline {
                        text-decoration: underline;
                        color: #f94054 !important;
                        font-weight: 500;
                        cursor: pointer;
                    }

                    .dropped {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 89px;
                        height: 32px;
                    }

                    .user_initial_container {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;

                        .user_initial {
                            min-height: 24px;
                            min-width: 24px;
                            max-height: 24px;
                            max-width: 24px;
                            border-radius: 50%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-right: 6px;
                            font-size: 10px;
                            font-weight: 500;
                            color: #fff;
                            padding-top: 2px;
                        }

                        .user_name {
                            text-wrap: nowrap;
                        }
                    }

                    .table_row_actions {
                        display: flex;
                        align-items: center;

                        img {
                            cursor: pointer;
                            margin-right: 13px;
                        }
                    }
                }

                .inactive {
                    color: #777776;

                    .underline {
                        text-decoration: underline;
                        color: #777776;
                        cursor: pointer;
                    }
                    .rejected_underline {
                        text-decoration: underline;
                        color: #f94054 !important;
                        font-weight: 500;
                        cursor: pointer;
                    }

                    .poc_trigger {
                        color: #777776;
                    }
                }
            }
        }
    }

    .table_col_highlighted {
        // display: inline-block;
        text-align: left;
        // width: calc(100% / 11);
        @include space(padding, all, 8px, 0px);
        color: $primary-blue;
        @include font-size(12);
        font-weight: 500;

        span {
            font-weight: 500;
        }

        svg {
            cursor: pointer;
        }

        &.table_col_disabled {
            color: #a5a5a4;

            svg {
                pointer-events: none;

                path {
                    fill: #a5a5a4;
                }
            }
        }

        &__unread {
            background-color: #fc441e;
            width: 6px;
            height: 6px;
            display: inline-block;
            border-radius: 50%;
            vertical-align: text-top;
        }
    }

    .add_user_mweb {
        position: fixed;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 50px;
        background-color: #fc5735;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 15px;
        font-weight: 500;
    }

    // @media(max-width:767px){
    //     .table_container{
    //         border: 1px solid #E5E7EB;
    //         // margin-top: 10px;
    //         height: 100%;
    //     }
    // }
    .common_menu {
        &__column {
            position: relative;
            @include space(padding, top-bottom, 10px, 10px);
            @include space(padding, left-right, 5px, 10px);
            @include font-size(12);
            @include flex-left;

            &__title {
                position: relative;
                @include flex-center;

                a {
                    cursor: pointer;
                    @include font-size(12);
                    @include flex-center;
                    color: #70758d;
                    border-radius: 50%;
                    // border: 1px solid #70758D;
                    text-decoration: none !important;
                    color: #fff !important;
                    height: 32px;
                    width: 32px;
                    font-weight: 500;
                }

                p {
                    font-weight: 600;
                    color: #101941;
                }
            }
        }

        &__chargers {
            display: flex;
            justify-content: space-between;
            width: 35px;
            text-align: center;
            align-items: end;
        }

        &__view {
            text-decoration: underline;
            color: #0430c0;
            cursor: pointer;
            padding-right: 5px;
        }

        &__menu {
            position: absolute;
            width: 250px;
            left: -120%;
            top: 30%;
            background: white;
            box-shadow: 0px 0px 8px 0px #d2d2d1;
            border-radius: 5px;
            min-height: 100px;
            z-index: 600;

            &__scope_title {
                color: #fd7c62;
                @include font-size(14);
                @include flex-space-between;
                @include space(padding, top-bottom, 10px, 0px);
                @include space(padding, left-right, 10px, 7px);
            }

            &__title {
                color: #101941;
                font-weight: 500;
                @include font-size(14);
                @include flex-space-between;
                @include space(padding, top-bottom, 10px, 0px);
                @include space(padding, left-right, 10px, 7px);

                &__icon {
                    svg {
                        height: 12px;
                    }
                }
            }

            &__description {
                @include space(padding, all, 10px, 0px);
                color: #404767;

                div {
                    @include flex-space-between;
                    @include font-size(12);
                    @include space(padding, top-bottom, 5px, 5px);

                    span {
                        font-weight: 400;
                        width: 50%;

                        &:last-child {
                            font-weight: 500;
                            @include font-size(14);
                        }
                    }
                }
            }
        }

        &__menu_backdrop {
            position: fixed;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: transparent;
            z-index: 500;
        }
    }

    .editProfile_modal {
        @include space(margin, top, 15px, 20px);

        &__form {
            &__flex {
                @include flex-space-between;
                width: 100%;
                @include space(margin, bottom, 10px, 0px);

                > div {
                    width: 50%;
                    @include space(padding, right, 10px, 0px);

                    &:last-child {
                        @include space(padding, left, 10px, 0px);
                    }

                    input:disabled {
                        cursor: not-allowed;
                    }
                }

                &__label {
                    color: #101941;
                    @include font-size(14);
                }

                &__date_container {
                    input {
                        width: 100%;
                        outline: 0;
                        border: 0;
                    }

                    input[type="date" i]::-webkit-calendar-picker-indicator {
                        cursor: pointer;
                        background-image: url(../../assets/images/calendar.svg);
                    }
                }

                &__inner {
                    &__content {
                        &:last-child {
                            @include space(margin, top, 10px, 0px);
                        }

                        &__radioButton {
                            @include flex-space-between;
                            width: 50%;

                            div {
                                @include flex-center;

                                span {
                                    @include font-size(12);
                                    @include space(margin, left, 5px, 0px);
                                }
                            }
                        }
                    }

                    &__lat_long {
                        @include flex-center;

                        div {
                            input {
                                &::placeholder {
                                    position: absolute;
                                    left: 5px !important;
                                }
                            }
                        }

                        &__latitude {
                            @include space(padding, right, 5px, 0px);
                        }

                        &__longitude {
                            @include space(padding, left, 5px, 0px);
                        }
                    }
                }

                &__description {
                    &__text {
                        color: #70758d;
                        @include font-size(10);
                    }
                }
            }

            &__flex_halfwidth {
                @include flex-space-between;
                flex-wrap: wrap;
                width: 50%;
                @include space(margin, bottom, 10px, 0px);

                &__title {
                    @include space(margin, bottom, 10px, 0px);

                    color: #101941;
                }

                &__inner {
                    &__content {
                        @include flex-space-between;

                        div {
                            @include flex-center;

                            span {
                                color: #101941;
                                @include font-size(14);
                                @include space(margin, left, 10px, 0px);
                            }

                            input {
                                width: 20px;
                                height: 20px;
                            }
                        }
                    }
                }
            }
        }

        .description_container {
            @include space(padding, top-bottom, 15px, 20px);

            label {
                @include font-size(14);
                @include space(padding, bottom, 5px, 0);
            }

            input {
                &::placeholder {
                    color: $base-grey;
                    position: absolute;
                    left: 0;
                }
            }
        }
    }

    .select_button {
        display: inherit;
        border: 1px solid $base-grey;
        max-height: 336px;
        height: 45px;
        width: 100%;
        overflow: auto;
        border-radius: 8px;
        // margin-top: 10px;
    }

    .button_container {
        position: relative;
        width: 100%;
        @include flex-right;
        margin-top: 20px;

        button {
            width: 140px;
            @include font-size(15);

            &:first-child {
                margin-right: 10px;
                background-color: $light-grey;
                color: $black;
            }

            &:last-child {
                margin-right: 10px;
                background-color: #fc5735;
                color: $white;
            }
        }
    }

    .block_wrapper {
        max-height: 135px;
        overflow-y: scroll;
    }

    .block_border {
        border: 1px solid #b7bac6;
        border-radius: 8px;
        padding: 10px;
    }

    .block_header {
        justify-content: space-between;
        align-items: center;
        color: rgb(183, 186, 198);
        font-size: 12px;
        font-weight: 400;
    }

    .left_heading {
        width: 45%;
        text-align: center;
    }

    .right_heading {
        width: 38%;
        text-align: left;
    }

    .block__input {
        @include flex-space-between;
        @include space(margin, top-bottom, 5px, 5px);

        &__inner {
            width: 60%;

            &:last-child {
                width: 40%;
            }

            div {
                width: 130px;
            }

            span {
                @include font-size(14);
                @include space(margin, left, 5px, 0px);

                color: #101941;
            }
        }
    }
}

.display_id {
    text-decoration: underline;
    color: #0430c0;
    cursor: pointer;

    img {
        position: relative;
        top: -2px;
        margin-left: 2px;
    }
}

@media (max-width: 767px) {
    .table_container {
        border: 1px solid #e5e7eb;
        margin-top: 5px;
        height: 100%;
        padding: 10px 15px;
    }
}

.add_user_mweb {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 50px;
    background-color: #fc5735;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    font-weight: 500;
}

/* triggers */
.location_trigger {
    width: 20px;
    height: 20px;
    color: #fc5735;
    background-color: #fec7bc;
    border-radius: 50%;
    font-size: 10px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-left: 5px;
}

.poc_trigger {
    text-decoration: underline;
    color: rgb(4, 48, 192) !important;
    cursor: pointer;
}

.locations_tooltip {
    width: 100%;
    height: 136px;
    // overflow-y: scroll;
    padding: 12px;
    overflow: auto;

    &::-webkit-scrollbar {
        width: 0px;
        /* width of the scrollbar */
        height: 0px;
    }

    .location_container {
        display: flex;
        justify-content: space-between;

        .location_name {
            color: #404767;
            font-size: 10px;
            font-weight: 400;
            margin-top: 6px;
            text-align: left;
        }

        .location_header {
            color: #404767;
            font-size: 10px;
            font-weight: 500;
            text-align: left;
        }

        .location_section_1 {
            flex: 1 !important;
            margin-right: 10px;
        }

        .location_section_2 {
            flex: 1 !important;
            margin-left: 10px;
            text-align: left;
        }
    }
}

.poc_tooltip {
    width: 100%;
    height: 100%;
    // overflow-y: scroll;
    padding: 12px;
    overflow: auto;

    &::-webkit-scrollbar {
        width: 0px;
        /* width of the scrollbar */
        height: 0px;
    }

    display: flex;
    justify-content: space-between;

    .poc_container {
        .poc_header {
            width: 50px !important;
            color: #70758d;
            font-weight: 400;
            margin-right: 10px;
        }

        .poc_data {
            text-wrap: wrap;
            font-weight: 500;
            text-align: left;
        }
    }
}
