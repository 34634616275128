
.wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 30px;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.switch_box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    position: relative;
    .tooltip {
        position: absolute;
        background-color: #111;
        color: #fff;
        width: 130px;
        top: -53px;
        padding: 3px 6px;
    }

}

/* Switch 1 Specific Styles Start */


input[type="checkbox"].switch {
    font-size: 30px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    // width: 30px;
    // height: 15px;
    background: #ddd;
    // border-radius: 3em;
    position: relative;
    cursor: pointer;
    outline: none;
    transition: all .2s ease-in-out;
    
    width: 36px;
    height: 20px;

    /* Primary Colours/Primary blue */

    // background: #101941;
    border-radius: 999px;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
    // background-image: url("../../assets/images/tick_gray.png");

    background-image: url("../../assets/images/tick_gray.png");
    background-size: contain;
    background-repeat: no-repeat;
    // background-position: right;
    background-position: 82% 51%;
    background-size: 6.93px 5.31px;
}

input[type="checkbox"].switch:checked {
    background: #101941;
}
input[type="checkbox"].switch:disabled {
    cursor: not-allowed !important;
}

input[type="checkbox"].switch:after {
    position: absolute;
    content: "";
    // width: 15px;
    // height: 15px;
    border-radius: 50%;
    background: #fff;
    box-shadow: 0 0 .25em rgba(0, 0, 0, .3);
    transform: scale(.7);
    left: 1px;
    top: 2px;
    transition: all .2s ease-in-out;

    ////////  
    // position: absolute;
    width: 16px;
    height: 16px;
    // left: 18px;
    // top: 2px;
    // background: #FFFFFF;
    // border-radius: 999px;
}

input[type="checkbox"].switch:checked:after {
    left: calc(100% - 17px);
    background-image: url("../../assets/images/tick_blue.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 45% 51%;
    background-size: 6.93px 5.31px;
}