@import "../../styles/mixins.scss";
@import "../../styles/variables.scss";

.backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(109 109 109 / 50%);
    z-index: 500;
}

.popup_container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 700;
    outline: 0;
    max-height: 600px;
    min-width: 300px;
    max-width: 800px;
    background: $white;
    overflow-x: hidden;
    overflow-y: auto;
    @include border-radius(10px);
    &__inr {
        &__description{
            img{
                width: 100%;
                max-height: 600px;
            }
        }
    }
}