@media(min-width:768px){.dropdown_container{
    color: #70758D;
    position: relative;
    width: 100%;
    .dropdown_container_body{
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 3px;
        width: 100%;
        height: 30px;
        background: rgba(232, 232, 236, 0.2);
        border-bottom: 1px solid #E5E7EB;
        justify-content: space-between;

        .selceted_options_container{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            overflow-x: auto;

            .selceted_option{
                font-size: 11px;    
                text-wrap: nowrap;
                font-weight: 400 !important;
            }
            
            .selceted_options{
                font-size: 11px;
                align-items: center;
                background-color: #e8e8ec;
                border-radius: 6px;
                display: flex;
                justify-content: space-between;
                margin-right: 5px;
                padding: 5px;
                min-width: 60px;
                max-height: 20px;
                z-index: 10;

                .close_icon{
                    width:12px;
                    height:15px;
                    cursor: pointer;
                }
            }
        }

        .selceted_options_container::-webkit-scrollbar{
            height: 0px;
            width:0px;
        }

        .body_title{
            font-weight: 400;
            font-size: 10px;
            // line-height: 13px;
            letter-spacing: 0.005em;
            text-transform: capitalize;
            color: #70758D;
        }

        .body_down_arrow{
            height: 6px;
            width: 10px;
            cursor: pointer;
            margin-left: 5px;
        }
    }

    .drop_down{
        margin-top: 5px;
        position: absolute;
        width: 100%;
        // height: 100%;
        max-height: 164px;
        background: #FAFAFB;
        box-shadow: -2px -2px 4px rgba(0, 0, 0, 0.05), 2px 2px 4px rgba(0, 0, 0, 0.05);
        // border-radius: 4px;
        z-index: 9999999999;
        overflow-y: scroll;
        // padding: 12px;
        .drop_down_option{
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            // line-height: 20px;
            display: flex;
            align-items: center;
            color: #101941;
            cursor: pointer;
            margin-bottom: 8px;
            padding: 0 12px;

            input[type="checkbox"] {
                box-sizing: border-box;
                width: 16px;
                height: 16px;
                min-width: 16px;
                min-height: 16px;
                max-width: 16px;
                max-height: 16px;
                border: 1px solid #B7BAC6;
                border-radius: 2px;
                margin-right: 12px;
                cursor: pointer;
              }
            //   margin: 5px;
        }
        .drop_down_option:hover{
            border-left: 2px solid #70758D;
            background-color: #E8E8Ec;
        }
    }

    .drop_down::-webkit-scrollbar{
        height: 0px;
        width: 0px;
    }
}}


@media(max-width:767px){.dropdown_container{
    position: relative;
    width: 100%;
    color: #70758D;
    .dropdown_container_body{
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 3px;
        width: 100%;
        height: 40px;
        background: rgba(232, 232, 236, 0.2);
        border-bottom: 1px solid #E5E7EB;
        justify-content: space-between;

        .selceted_options_container{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            overflow-x: scroll;

            .selceted_option{
                font-size: 11px !important;    
                text-wrap: nowrap;
                font-weight: 400 !important;
            }
            
            .selceted_options{
                font-size: 11px !important;
                align-items: center;
                background-color: #e8e8ec;
                border-radius: 6px;
                display: flex;
                justify-content: space-between;
                margin-right: 5px;
                padding: 5px;
                min-width: 60px;
                max-height: 20px;
                z-index: 10;

                .close_icon{
                    width:12px;
                    height:15px;
                    cursor: pointer;
                }
            }
        }

        .selceted_options_container::-webkit-scrollbar{
            height: 0px;
            width:0px;
        }

        .body_title{
            font-weight: 400;
            font-size: 10px !important;
            line-height: 13px;
            letter-spacing: 0.005em;
            text-transform: capitalize;
            color: #70758D;
        }

        .body_down_arrow{
            height: 6px;
            width: 10px;
            cursor: pointer;
            margin-left: 5px;
        }
    }

    .drop_down{
        margin-top: 5px;
        position: absolute;
        width: 100%;
        max-height: 116px;
        overflow-y: scroll;
        background: #FAFAFB;
        box-shadow: -2px -2px 4px rgba(0, 0, 0, 0.05), 2px 2px 4px rgba(0, 0, 0, 0.05);
        z-index: 9999999999;
        overflow-y: scroll;
        padding: 12px 0px;
        .drop_down_option{
            margin-bottom: 8px;
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            display: flex;
            align-items: center;
            color: #101941;
            padding: 5px 12px;

            input[type="checkbox"] {
                box-sizing: border-box;
                width: 16px;
                height: 16px;
                min-width: 16px;
                min-height: 16px;
                max-width: 16px;
                max-height: 16px;
                border: 1px solid #B7BAC6;
                border-radius: 2px;
                margin-right: 12px;
                cursor: pointer;
              }
        }
        .drop_down_option:hover{
            border-left: 2px solid #70758D;
            background-color: #E8E8Ec;
        }
    }

    .drop_down::-webkit-scrollbar{
        height: 0px;
        width: 0px;
    }
}}